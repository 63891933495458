// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import GroupAttr from "../GroupAttr";
import FiletypeIcon from "../icons/FiletypeIcon";
import ListItemBase from "./ListItemBase";
import useDatetime from "../../hooks/usedatetime";

type Props = {
    id: string;
    name?: string;
    mime?: string;
    size?: number;
    modified?: string;
    action?: React.ReactNode;
    border?: boolean;
    inactive?: boolean;
    key?: string;
    page?: string;
};

const ListItemDownload: React.FC<Props> = ({ id, name, inactive, modified, action, border, mime, key, page }) => {
    const { fDate } = useDatetime();
    return (
        <ListItemBase
            key={key}
            id={id}
            name={name}
            action={action}
            border={border}
            inactive={inactive}
            mime={mime}
            page={page}
            icon={<FiletypeIcon filetype={mime ?? "zip"} color="secondary" sx={{ fontSize: { xs: 28, sm: 28 } }} />}
        >
            <Stack direction="row" spacing={4}>
                {modified && <GroupAttr label="Last updated" value={fDate(modified)} minWidth="auto" />}
            </Stack>
        </ListItemBase>
    );
};

export default ListItemDownload;
