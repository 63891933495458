// Copyright (C) 2022 TANNER AG

import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import PositionListItem from "./listitem/PositionListItem";
import PositionToolbar from "./PositionToolbar";
import Pagination from "./Pagination";
import usePositions from "../../hooks/usepositions";
import PositionListNoResult from "./PositionListNoResult";
import { Box, CircularProgress, styled } from "@mui/material";
import useParam from "../../../../hooks/useparam";

type Props = {
    id: string;
    fetching: boolean;
    setFetchingPosition: any
};

const PositionList: React.FC<Props> = ({ id, fetching: fetchingPosition, setFetchingPosition }) => {
    const { items, total, levels, types, fetching, refetchPosition } = usePositions(id);
    const [posId] = useParam("pos");
    useEffect(() => {
        refetchPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingPosition]);
    useEffect(() => {
        setFetchingPosition(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!fetching]);
    return (
        <>
            {fetching ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <HeaderContainer width={"100vw"} open={Boolean(!posId)}>
                        <PositionToolbar id={id} levels={levels} types={types} total={total} />
                    </HeaderContainer>
                    {Boolean(!fetching && !items.length) && <PositionListNoResult />}
                    <Stack spacing={2} sx={{ marginTop: "100px" }}>
                        {items.map((position) => (
                            <PositionListItem
                                key={position.id}
                                position={position}
                                id={id}
                                refetchPosition={refetchPosition}
                            />
                        ))}
                    </Stack>
                    <Pagination total={total} />
                </>
            )}
        </>
    );
};

const HeaderContainer = styled(Box, { shouldForwardProp: (props) => props !== "width" })<{
    width: string;
    open: boolean;
}>(({ theme, width, open }) => ({
    display: "flex",
    position: "fixed",
    height: "7vh",
    width: width,
    marginLeft: open ? 400 : 0,
    alignItems: "center",
    zIndex: 1022,
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper
}));


export default PositionList;
