import React, { useState } from "react";
import {
    List,
    ListItemText,
    Collapse,
    styled,
    ListItemButton
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

interface SidebarItem {
    level1: string;
    level2: string[];
}

interface SidebarProps {
    data: SidebarItem[];
    id: string;
}

const DrawerDiv = styled("div")`
  overflow-y: auto;
  height: 70vh;
  position: absolute;
`;

const Sidebar: React.FC<SidebarProps> = ({ data }) => {
    const [open, setOpen] = useState<number[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams({});
    const [selectedItem, setSelectedItem] = useState<string>("");

    const handleClick = (index: number, level1: string, level2: string[]) => {
        const currentIndex = open.indexOf(index);
        const newOpen = [...open];
        if (level2.length > 0) {
            if (currentIndex === -1) {
                newOpen.length = 0;
                newOpen.push(index);
            } else {
                newOpen.splice(currentIndex, 1);
            }
            if (open.includes(index)) {
                setSearchParams({});
            } else {
                setSearchParams({ level: level1 });
            }

            setOpen(newOpen);

        } else {
            setOpen([]);
            setSearchParams({ level: level1 });
        }
        setSelectedItem(level1);
    };

    const secondLevelOnClickHandler = (level2: string) => {
        setSearchParams({ level: level2 });
        setSelectedItem(level2);
    };

    const renderLevel2Items = (level2: string[], index: number) => {
        return (
            <Collapse in={open.includes(index)} timeout="auto" unmountOnExit key={`sublist-${index}`}>
                <List component="div" disablePadding>
                    {level2.map((item, subIndex) => (
                        <ListItemButton selected={item === selectedItem} key={`subitem-${index}-${subIndex}`}
                                        onClick={() => secondLevelOnClickHandler(item)}>
                            <ListItemText secondary={item} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        );
    };

    return (

        <DrawerDiv>
            <List>
                {data?.map((item, index) => (
                    <div key={`item-${index}`}>
                        <ListItemButton selected={item.level1 === selectedItem}
                                        onClick={() => handleClick(index, item.level1, item.level2)}>
                            <ListItemText primary={item.level1} sx={{ color: "#4E6174" }}
                                          primaryTypographyProps={{ fontWeight: "bold" }} />
                            {(item.level2.length !== 0) ? (
                                <>
                                    {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                                </>
                            ) : null}
                        </ListItemButton>
                        {renderLevel2Items(item.level2, index)}
                    </div>
                ))}
            </List>

        </DrawerDiv>

    );
};
export default Sidebar;