import React, { ChangeEvent, FC } from "react";
import { Tender } from "../../../graphql";
import Typography from "@mui/material/Typography";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import { Checkbox } from "@mui/material";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import { CheckIcon } from "../../../components/icons/MANIcons";
import Dialog from "../../../components/dialog/Dialog";
import Stack from "@mui/material/Stack";
import { useStylesDocumentSelection } from "../../../hooks/useStyle";

interface Props {
    specificationData?: any;
    open: boolean;
    closeModalHandler: (reason: "backdropClick" | "escapeKeyDown") => void;
    fetching?: boolean;
    changeCheckboxHandler?: (event: ChangeEvent<HTMLInputElement>, fileName: string) => void;
    saveSpecificationData?: (data: Tender) => Promise<void>;
    closeModalAction?: React.ReactNode;
    editPageComponent?: React.ReactNode;
}


export const DocumentPopupSelection: FC<Props> = ({
                                                      open,
                                                      saveSpecificationData,
                                                      specificationData,
                                                      changeCheckboxHandler,
                                                      closeModalHandler,
                                                      fetching,
                                                      closeModalAction,
                                                      editPageComponent
                                                  }) => {
    const classes = useStylesDocumentSelection();
    const parsePathFromOriginal = (path: string) => {
        if (path) {
            const regex = /\/original\/(.+)/;
            const match = path.match(regex);
            if (match) {
                const extractedPath = match[1];
                return extractedPath;
            }
        }
    };
    return (
        <Dialog open={open} onClose={(__, reason) => closeModalHandler(reason)} className={classes.documentModal}>
            <Typography variant="h3" className={classes.title}>LASTENHEFT(E) AUSWÄHLEN</Typography>
            <Typography variant="h5" className={classes.title}>Bitte wähle aus den folgenden Dokumenten die
                Lastenhefte aus</Typography>
            {specificationData?.files?.map((row: any, index: any) => (
                <>
                    <ListItemDownload
                        id=""
                        name={parsePathFromOriginal(row.path)}
                        mime={parsePathFromOriginal(row.path)?.split(".").pop()}
                        // border
                        action={
                            <Stack spacing={1}
                                   sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "center",
                                       position: "absolute",
                                       right: 0
                                   }}>
                                {editPageComponent}
                                <Checkbox
                                    onChange={(event) => changeCheckboxHandler?.(event, row)}
                                />
                            </Stack>
                        }
                    />
                    <Divider />
                </>
            ))
            }
            <Stack direction="row" spacing={1} sx={{ display: "flex" }}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    // disabled={!file}
                    loading={fetching}
                    type="submit"
                    fullWidth
                    loadingPosition="center"
                    startIcon={<CheckIcon />}
                    onClick={() => saveSpecificationData?.(specificationData)}
                >Abschicken</LoadingButton>
                {closeModalAction}
            </Stack>
        </Dialog>
    );
};