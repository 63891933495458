// Copyright (C) 2022 TANNER AG

import React, { ReactNode} from "react";
import { ChevronDownIcon } from "../icons/MANIcons";
import { styled } from "@mui/material/styles";
import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";

type Props = {
    id: string;
    label?: {label:string, labelIcon:ReactNode};
    helperText?: string;
    required?: boolean;
    value?:string | null;
    width?:number;
    handleChange?:(event:SelectChangeEvent<string>)=>void;
    options: { value: string; label: string }[];
};

const FormSelect: React.FC<Props> = ({ value, options, handleChange, label, width }) => {
    
    
    return (
        <FormControl sx={{ m: 1, minWidth: width}}>
            <SelectDiv>
            <Select
                disableUnderline
                IconComponent={ChevronDownIcon}
                value={value??"label"}  
                onChange={handleChange}
            >
                <MenuItem value={"label"}>
                    <div className="label-icon">
                    {label?.labelIcon}
                    </div>
                    <ListItemText primaryTypographyProps={{fontSize: '14px'}} primary={label?.label} />
                </MenuItem>
                {options.map(({ label, value }) => (
                    <MenuItem value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            </SelectDiv>

        </FormControl>
    );
};
const SelectDiv = styled('div')(({theme})=>({
    "& .MuiOutlinedInput-root":{
        height:"32px",
        width:"fit-content",
        "& .MuiSelect-select":{
            display:"flex",
            alignItems:"center"
        }
    },
    "& .MuiSvgIcon-root":{
        width:"10.5px"
    },
    "& .label-icon":{
        display:"flex",
        "& .MuiSvgIcon-root":{
            width:"16px"
        }
    }
}));

export default FormSelect;
