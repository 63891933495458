// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import PositionFilterInput from "./PositionFilterInput";
import Typography from "@mui/material/Typography";
// import useTranslation from "../../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import PositionToolbarCat from "./PositionToolbarCat";
import useStore from "../../../../hooks/usestore";
import shallow from "zustand/shallow";
import Button from "@mui/material/Button";
import { ArrowRightIcon, CloseIcon } from "../../../../components/icons/MANIcons";
import PositionFilterType from "./PositionFilterType";
import { useTenderQuery } from "../../../../graphql";
import Dialog from "../../../../components/dialog/Dialog";
import ListItemDownload from "../../../../components/listitem/ListItemDownload";
import Divider from "@mui/material/Divider";
import { parsePathFromOriginal } from "./details/PositionDetails";
import DialogHead from "../../../../components/dialog/DialogHead";
import useTranslation from "../../../../hooks/usetranslation";
import { useStylesDocumentSelection } from "../../../../hooks/useStyle";
import useParam from "../../../../hooks/useparam";

type Props = {
    id: string;
    levels: string[];
    types: string[];
    total: number;
};

const PositionToolbar: React.FC<Props> = ({ types, id }) => {
    const { t } = useTranslation();
    const [posId] = useParam("pos");
    const [assignMode, setAssignMode] = useStore((state) => [state.assignMode, state.setAssignMode], shallow);
    const [{ data: tenderData }] = useTenderQuery({ variables: { id } });
    const [selectDocumentModal, setSelectDocumentModal] = useState(false);
    const setDoc = useStore((state) => state.setDoc);
    const classes = useStylesDocumentSelection();
    const handleCloseDocumentSelectionModal = () => {
        setSelectDocumentModal(false);
    };
    return (
        <>
            {tenderData &&
                <Dialog open={selectDocumentModal}
                        onClose={handleCloseDocumentSelectionModal}
                        className={classes.documentModal}
                >
                    <DialogHead title={"Dokumentenübersicht"} onClose={() => setSelectDocumentModal(false)} />
                    {tenderData?.tender?.files?.map((row: any, index: any) => (
                        <Stack>
                            <ListItemDownload
                                id={index}
                                key={index}
                                name={parsePathFromOriginal(row.path)}
                                mime={parsePathFromOriginal(row.path)?.split(".").pop()}
                                // border
                                action={
                                    <Stack spacing={1}
                                           sx={{
                                               display: "flex",
                                               flexDirection: "row",
                                               alignItems: "center",
                                               position: "absolute",
                                               right: 0
                                           }}>

                                        {parsePathFromOriginal(row.path)?.split(".").pop() === "pdf" ? (
                                            <Button
                                                onClick={() =>
                                                    setDoc({
                                                        title: row.name,
                                                        subTitle: "",
                                                        src: row.fileUrl
                                                    })
                                                }
                                                startIcon={<ArrowRightIcon color="secondary" />}
                                            >
                                                {t("open_file")}
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    window.open(row.fileUrl, "_blank")
                                                }
                                            >
                                                {"Download"}
                                            </Button>
                                        )}
                                    </Stack>

                                }
                            />
                            <Divider />
                        </Stack>
                    ))
                    }
                </Dialog>}
            {assignMode && (
                <Stack
                    direction="row"
                    sx={{
                        mb: 2,
                        alignItems: "center",
                        bgcolor: "info.main",
                        mt: -2,
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                        px: 3,
                        py: 1.5,
                        color: "common.white"
                    }}
                    spacing={2}
                >
                    <Button
                        onClick={() => setAssignMode(false)}
                        startIcon={<CloseIcon fontSize="small" />}
                        variant="outlined"
                        sx={{ borderColor: "common.white" }}
                    >
                        Schließen
                    </Button>
                    <Typography variant="h5" color="inherit">
                        Zuordnungsmodus
                    </Typography>
                </Stack>
            )}

            <PositionToolbarCat />
            <Stack direction="row" sx={{ alignItems: "center", marginLeft: posId ? "420px" : "20px" }}>
                <Grid container spacing={1} sx={{ alignItems: "center" }}>
                    <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", alignItems: "center" }}>
                        <PositionFilterInput />
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <PositionFilterType types={types} />
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <Button variant="contained"
                                sx={{ width: "fit-content", marginLeft: "20px", borderRadius: "20px" }}
                                onClick={() => setSelectDocumentModal(true)}>Dokumentenübersicht</Button>
                    </Grid>

                </Grid>
            </Stack>
        </>
    );
};

export default PositionToolbar;
