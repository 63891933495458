// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import ResultListItem from "./resultlist/ResultListItem";
import Filter from "./filter/Filter";
import useSearch from "./hooks/usesearch";
import Pagination from "./resultlist/Pagination";
import ResultListLoading from "./resultlist/ResultListItemSkeleton";
import Dialog from "../../components/dialog/Dialog";
import { CloseIcon, SearchIcon } from "../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogHead from "../../components/dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTenderQuery } from "../../graphql";
import { useParams } from "react-router-dom";

const SearchView: React.FC = () => {
    const { total, items, fetching } = useSearch();
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const [{ data }] = useTenderQuery({ variables: { id: String(id) } });

    return (
        <>
            <Button startIcon={<SearchIcon />} variant="contained" color="secondary" onClick={() => setOpen(true)}>
                Anlagen durchsuchen
            </Button>
            <Dialog
                open={open}
                maxWidth="lg"
                onClose={() => setOpen(false)}
                sx={{ "& .MuiDialog-paper": { backgroundColor: "rgb(234, 237, 243)" } }}
            >
                <Stack sx={{ p: 3, pb: 0 }}>
                    <DialogHead
                        title="Anlagen durchsuchen"
                        subTitle={data?.tender?.name}
                        onClose={() => setOpen(false)}
                    />
                </Stack>
                <DialogContent>
                    <Filter total={total} />
                    <Stack spacing={2}>
                        <ResultListLoading loading={fetching} />
                        {!fetching && items.map((item, index) => <ResultListItem key={index} item={item} />)}
                    </Stack>
                    {total > 0 && <Pagination total={total} page={1} onChange={() => null} />}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon fontSize="small" />}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SearchView;
