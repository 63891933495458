// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import AppMenuItem from "./AppMenuItem";
import useTranslation from "../../hooks/usetranslation";

const AppMenu: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1.5, lg: 3 }} sx={{ mt: 0 }}>
            {[
                { title: "product_fahrzeuge", to: "/" },
                { title: "products_solution", to: "/" },
                { title: "products_emobility", to: "/" },
                { title: "products_sale_support", to: "/" },
                { title: "products_body_builder", to: "/" },
                { title: "tenders", to: "/tenders" }
            ].map((navItem, index) => (
                <AppMenuItem key={index} title={t(navItem.title)} to={navItem.to} />
            ))}
        </Stack>
    );
};

export default AppMenu;
