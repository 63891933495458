// Copyright (C) 2022 TANNER AG

import { usePositionsQuery } from "../../../graphql";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useStore from "../../../hooks/usestore";
import { PositionsQuery } from "../../../graphql";

const RESULTS_PER_PAGE = 10;

const usePositions = (id: string) => {
    const assignMode = useStore((state) => state.assignMode);
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";
    const page = searchParams.get("page") ?? "1";
    const level = searchParams.get("level") ?? "";
    const cat = searchParams.get("cat") ?? "";
    const type = searchParams.get("type") ?? "";
    const specification = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "";
    const language = JSON.parse(localStorage.getItem(id) || "{}").language ?? "";

    const [start, limit] = useMemo(() => {
        return [(Number(page) - 1) * RESULTS_PER_PAGE, RESULTS_PER_PAGE];
    }, [page]);

    const [{ data, fetching, error }, refetch] = usePositionsQuery({
        variables: {
            where: { id, query, level, cat, assignMode, type, specification, language },
            pagination: { start, limit }
        },
        requestPolicy: "network-only"
    });
    const dataItem: any = data?.positions?.items;

    let groupedDataItems: { id: number, label: string, items: PositionsQuery }[] = [];

    if (dataItem) {
        groupedDataItems = Object.entries(
            dataItem?.reduce((acc: any, index: any) => {
                if (!acc[index.levels[1]]) {
                    acc[index.levels[1]] = [];
                }

                acc[index.levels[1]].push(index);

                return acc;
            }, {})
        ).map(([label, items], index) => ({ label, items, id: index })) as any;
    }


    return {
        total: data?.positions?.total ?? 0,
        items: groupedDataItems,
        levels: data?.positions?.filter?.levels ?? [],
        types: data?.positions?.filter?.types ?? [],
        fetching,
        refetchPosition: refetch,
        error
    };
};

export default usePositions;