import { createStyles, makeStyles } from "@mui/styles";

const useStylesDocumentSelection = makeStyles(() =>
    createStyles({
        title: {
            padding: "20px"
        },
        documentModal: {
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "70vw"  // Set your width here
                }
            }
        }
    })
);


export { useStylesDocumentSelection };