import { useSearchParams } from "react-router-dom";
import { useSidebarLevelsQuery } from "../../../graphql";
import useStore from "../../../hooks/usestore";


const useSidebarLevels = (id: string) => {
    const assignMode = useStore((state) => state.assignMode);
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";
    const level = searchParams.get("level") ?? "";
    const cat = searchParams.get("cat") ?? "";
    const type = searchParams.get("type") ?? "";
    const specification = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "";
    const language = JSON.parse(localStorage.getItem(id) || "{}").language ?? "";

    const [{ data }, refetch] = useSidebarLevelsQuery({
        variables: { where: { id, query, level, cat, assignMode, type, specification, language } },
        requestPolicy: "network-only"
    });
    let output = [];

    const sidebarLevelData = data?.positions?.filter?.levels?.filter((item) => {
        return !/\d+\.\d+\.\d/.test(item);
    });

    output = sidebarLevelData?.reduce((acc: any, curr: any) => {
        const regex = /^(\d+\.\s)|(\d+\.0+\..+)$/;
        const isLevel1 = regex.test(curr);
        if (isLevel1) {
            acc.push({ level1: curr, level2: [] });
        } else {
            acc[acc.length - 1].level2.push(curr);
        }

        return acc.sort();
    }, []);

    return {
        data: output,
        specifications: data?.positions?.specifications ?? [],
        refetchSidebar: refetch
    };
};
// console.log('sidebar data', data?.positions?.filter?.levels);
export default useSidebarLevels;