// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import PositionList from "./positions/PositionList";
import MainWrapper from "../../../components/MainWrapper";
import TenderSidebar from "./TenderSidebar";
import { useParams } from "react-router-dom";
import PositionDetails from "./positions/details/PositionDetails";

const TenderDetails: React.FC = () => {
    const { id } = useParams();
    const [fetching, setFetching] = useState(false);
    return (
        <MainWrapper sidebarLeft={<TenderSidebar id={String(id)} setFetchingPosition={setFetching} />}
                     sidebarRight={<PositionDetails id={String(id)} />}>
            <PositionList id={String(id)} fetching={fetching} setFetchingPosition={setFetching} />
        </MainWrapper>
    );
};

export default TenderDetails;
