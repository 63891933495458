// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import FormSelect from "../../../../components/inputs/FormSelect";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { TurnedInNotOutlined } from "@mui/icons-material";

type Props = {
    types: string[];
};

const PositionFilterType: React.FC<Props> = ({ types }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = (searchParams.get("type")) === "" ? "label" : searchParams.get("type");
    const [selectedValue, setSelected] = useState(value);
    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelected(event.target.value);
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("page");
        newSearchParams.set("type", event.target.value === "label" ? "" : event.target.value);
        setSearchParams(newSearchParams);
    };
    const label = {
        label: "Typ",
        labelIcon: <TurnedInNotOutlined sx={{ mr: 1 }} />
    };

    return (
        <FormSelect
            id="type"
            label={label}
            options={[...types.map((type) => ({ label: type, value: type }))]}
            value={selectedValue}
            width={150}
            handleChange={handleChange}
        />
    );
};

export default PositionFilterType;
