// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useState } from "react";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { DeleteIcon, FileUploadIcon, ZIPIcon } from "../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import IconButton from "@mui/material/IconButton";
import FormInput from "../../../components/inputs/FormInput";

type Props = {
    head?: React.ReactNode;
    children?: React.ReactNode;
    error?: boolean;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit?(file: File, tenderName: String): void;
};

const ProjectUploadForm: React.FC<Props> = ({ head, onSubmit, children, error, setError }) => {
    const [file, setFile] = useState<File>();
    const [isNotValidZipFile, setIsNotValidZipFile] = useState<Boolean>(false);
    const [value, setValue] = useState("");
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles.length) {
                return;
            } else {
                setFile(acceptedFiles[0]);
                setIsNotValidZipFile(false);
                setError(false);
            }
        }
    });

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if ((file?.type === "application/zip" || file?.type === "application/x-zip-compressed") && value !== "") {
            onSubmit?.(file, value);
            setIsNotValidZipFile(false);
        } else {
            setIsNotValidZipFile(true);
        }
    };

    const onDeleteHandler = () => {
        setFile(undefined);
        setIsNotValidZipFile(false);
        setError(false);
    };

    return (
        <Stack component="form" sx={{ overflow: "hidden" }} onSubmit={handleSubmit}>
            <DialogContent sx={{ pb: 0 }}>
                {head}
                <Stack sx={{ mb: 3 }}>
                    <FormInput
                        id="test"
                        label="Namer der Auschreibung"
                        InputProps={{
                            value,
                            onChange: (event) => setValue(event.currentTarget.value)
                        }}
                    />
                </Stack>
                {file && (
                    <Stack>
                        <ListItemDownload
                            id=""
                            name={file.name}
                            modified={new Date(file.lastModified).toISOString()}
                            size={file.size}
                            mime={file.type}
                            border
                            action={
                                <IconButton onClick={onDeleteHandler}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                        {isNotValidZipFile ? (
                            <Typography variant="body2">
                                Please reselect Zip format file
                            </Typography>
                        ) : null}
                        {error ? (
                            <Typography variant="body2">
                                There was an unexpected system error. Please contact admin.
                            </Typography>
                        ) : null}
                    </Stack>
                )}
                {!file && (
                    <Dropzone {...getRootProps()}>
                        <ZIPIcon fontSize="large" sx={{ mb: 2, color: "grey.500" }} />
                        <Button startIcon={<FileUploadIcon />} variant="contained" color="secondary" sx={{ mb: 1.5 }}>
                            ZIP-Datei auswählen...
                        </Button>
                        <input {...getInputProps()} type="file" />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <Typography variant="body2">
                                oder ziehen Sie die Datei hier in das Feld zum Hochladen.
                            </Typography>
                        )}
                    </Dropzone>
                )}
            </DialogContent>
            <DialogActions>{children}</DialogActions>
        </Stack>
    );
};

const Dropzone = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    minHeight: 140,
    border: "1px dashed",
    borderColor: theme.palette.grey["500"],
    backgroundColor: theme.palette.grey["100"],
    borderRadius: 8,
    boxSizing: "border-box",
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
}));

export default ProjectUploadForm;
