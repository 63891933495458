// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ListItemWrapper } from "../../../../../components/listitem/ListItemRegular";
import { Keyword, Position, usePositionQuery } from "../../../../../graphql";
import useParam from "../../../../../hooks/useparam";
import Chip from "@mui/material/Chip";
import useHTMLParser from "../../../../../hooks/usehtmlparser";
import { Grid, Menu, MenuItem } from "@mui/material";
import { getToken } from "../../../../../lib/urql";


type Props = {
    position: any
    subItems?: Pick<Position, "id" | "description">[];
    keywords?: Pick<Keyword, "key" | "text">[];
    id: string;
    refetchPosition?: any
};

const PositionListItem: React.FC<Props> = ({ position, id, refetchPosition }) => {
    const { parseHTML } = useHTMLParser();
    const [posId, setPos] = useParam("pos");
    const specification = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "";
    const language = JSON.parse(localStorage.getItem(id) || "{}").language ?? "";
    const [{ data }] = usePositionQuery({
        variables: { id: id!, posId, specification, language },
        pause: !posId,
        requestPolicy: "network-only"
    });
    const [menu, setMenu] = useState<null | HTMLElement>(null);
    const updatePositionData = async (item: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/position/update`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/Json"
                },
                body: JSON.stringify(item)
            });
            if (response) {
                handleCloseMenu();
                refetchPosition();
            }
        } catch (e) {
            console.log(e);
        }
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setMenu(event.currentTarget);
    };
    const dummyData = [{
        id: 0,
        text: "Muss",
        value: "M"
    }, {
        id: 1,
        text: "Soll",
        value: "S"
    }];

    const handleCloseMenu = () => {
        setMenu(null);
    };

    const handleLabelsMenuItemClick = (option: {
        id: number,
        text: string,
        value: string
    }) => {
        if (data && data.position) {
            updatePositionData({ ...data.position, type: option.value });
            handleCloseMenu();
        }
    };

    const handleSetPos = (e: React.MouseEvent<HTMLDivElement>, posid: string) => {
        e.stopPropagation();
        setPos(posid);
    };
    return (
        <>
            <Typography
                key={position.id}
                variant="h4"
                sx={{ lineHeight: "28px", fontSize: 16, color: "text.secondary" }}
            >
                {position.label === "undefined" ? position?.items[0]?.levels[0] : position.label}
            </Typography>
            {position.items.map((item: any) => (
                <ListItemWrapper selected={posId === item.id} key={item.id}>
                    <Stack sx={{ p: 1, pt: 1.5, pl: 1.5, flexGrow: 1, cursor: "pointer" }}
                    >
                        <Grid container onClick={(e) => handleSetPos(e, item.id)}>
                            <Grid item md={9}>
                                <Typography variant="body2" sx={{ pr: 2 }} component="div" className="html-content">
                                    {parseHTML(item.description)}
                                </Typography>
                            </Grid>
                            <Grid item md={3}
                                  sx={{ justifyContent: "center", display: "flex", height: "fit-content" }}>
                                <Chip label={item.type === "M" ? "Muss" : "Soll"}
                                      size="small"
                                      onClick={(e) => handleOpenMenu(e)}
                                      sx={{
                                          backgroundColor: (item.type === "M") ? "#E40045" : "#FFD743",
                                          size: "12px",
                                          fontWeight: "400",
                                          color: (item.type === "M") ? "white" : "black"
                                      }} />
                                <Menu
                                    anchorEl={menu}
                                    open={Boolean(menu)}
                                    onClose={handleCloseMenu}
                                    variant="menu"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 200,
                                            width: "fit-content"
                                        }
                                    }}
                                >
                                    {dummyData?.map((item, index) => (
                                        <MenuItem key={index} onClick={(e) => {
                                            e.stopPropagation();
                                            handleLabelsMenuItemClick(item);
                                        }}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                                </Menu>

                            </Grid>
                        </Grid>
                    </Stack>
                </ListItemWrapper>
            ))}
        </>
    );
};

export default PositionListItem;
