// Copyright (C) 2022 TANNER AG

import React, { ChangeEvent, useState } from "react";
import useParam from "../../../../../hooks/useparam";
import DialogHead from "../../../../../components/dialog/DialogHead";
import { usePositionQuery, useTenderQuery } from "../../../../../graphql";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ArrowRightIcon, CheckIcon, ChevronRightIcon } from "../../../../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import { ListItemWrapper } from "../../../../../components/listitem/ListItemRegular";
import { Checkbox, Menu, styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import useHTMLParser from "../../../../../hooks/usehtmlparser";
import Chip from "@mui/material/Chip";
import PositionDetailsGroup from "./PositionDetailsGroup";
import ListItemDownload from "../../../../../components/listitem/ListItemDownload";
import Button from "@mui/material/Button";
import useTranslation from "../../../../../hooks/usetranslation";
import useStore from "../../../../../hooks/usestore";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { scrollbarSmall } from "../../../../../utils";
import HtmlContent from "../../../../../components/htmlcontent/HtmlContent";
import { PlusIcon } from "../../../../../components/icons/MANIcons";
import Divider from "@mui/material/Divider";
import { MenuItem } from "@mui/material";
import usePositionsLevels from "../../../hooks/usePositionsLevels";
import IconButton from "@mui/material/IconButton";
import { getToken } from "../../../../../lib/urql";
import usePositions from "../../../hooks/usepositions";
import FormInput from "../../../../../components/inputs/FormInput";
import Dialog from "../../../../../components/dialog/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStylesDocumentSelection } from "../../../../../hooks/useStyle";

type Props = {
    enableProjectUpdate?: boolean;
    id: string;
};
export const parsePathFromOriginal = (path: string) => {
    if (path) {
        const regex = /\/original\/(.+)/;
        const match = path.match(regex);
        if (match) {
            const extractedPath = match[1];
            return extractedPath;
        }
    }
};

const PositionDetails: React.FC<Props> = ({ id }) => {
    const [posId, setPosId] = useParam("pos");
    const specification = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "";
    const language = JSON.parse(localStorage.getItem(id) || "{}").language ?? "";
    const [{ data }, refetch] = usePositionQuery({
        variables: { id: id, posId, specification, language },
        pause: !posId,
        requestPolicy: "network-only"
    });
    const [selectDocumentModal, setSelectDocumentModal] = useState(false);
    const [{ data: tenderData }] = useTenderQuery({ variables: { id } });
    const { refetchPosition } = usePositions(id);
    const { levels, labels } = usePositionsLevels(id);
    const { parseHTML } = useHTMLParser();
    const { t } = useTranslation();
    const setDoc = useStore((state) => state.setDoc);
    const [labelsMenu, setLabelsMenu] = useState<null | HTMLElement>(null);
    const [chapterMenu, setChapterMenu] = useState<null | HTMLElement>(null);
    const [value, setValue] = useState("");
    const classes = useStylesDocumentSelection();
    const updatePositionData = async (item: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/position/update`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/Json"
                },
                body: JSON.stringify(item)
            });
            if (response) {
                refetch();
                refetchPosition();
            }
        } catch (e) {
            console.log(e);
        }
    };

    //Chapter Menu handlers
    const handleOpenChapterMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setChapterMenu(event.currentTarget);
    };

    const handleCloseChapterMenu = () => {
        setChapterMenu(null);
    };

    const handleChapterMenuItemClick = async (option: any) => {
        // setSelectedChapterValue(option);
        // setPositionItem({ ...data!.position, levels: option });
        if (data && data.position) {
            updatePositionData({ ...data!.position, levels: option });
            handleCloseChapterMenu();
        }

    };
    //Labels Menu handlers
    const handleOpenLabelsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLabelsMenu(event.currentTarget);
    };

    const handleCloseLabelsMenu = () => {
        setLabelsMenu(null);
    };

    const handleLabelsMenuItemClick = (option: {
        key: string,
        text: string,
        __typename: string
    }) => {
        if (data && data.position) {
            const currentKeywords = data.position.keywords!;
            updatePositionData({ ...data!.position, keywords: [...currentKeywords, option] });
            handleCloseLabelsMenu();
        }
    };
    const handleDeleteLabelsChip = (label: any) => {
        const remainKeywords = data?.position?.keywords?.filter(originalKeyword => originalKeyword !== label);
        updatePositionData({ ...data!.position, keywords: remainKeywords });
    };

    const handleClose = () => setPosId();

    const handleCloseDocumentSelectionModal = (reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
            return;
        } else {
            setSelectDocumentModal(false);
        }
    };


    const referencesDocuments = data?.position?.references?.filter((reference) => reference.type === "document") ?? [];

    const [documentData, setDocumentData] = useState<any>();

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, row: any, index: any) => {
        const checked = event.target.checked;
        const currentReferences = documentData?.references ?? [];
        if (checked) {
            if (value !== "") {
                setDocumentData({
                    ...data?.position,
                    references: [...currentReferences, { id: row.path, name: row.name, type: "document", page: value }]
                });
            } else {
                setDocumentData({
                    ...data?.position,
                    references: [...currentReferences, { id: row.path, name: row.name, type: "document", page: "0" }]
                });
            }
        } else if (!checked) {
            setValue("");
            setDocumentData({
                ...data?.position,
                references: currentReferences.filter((item: any) => item.id !== row.path)
            });
        }
    };

    const handleOnChangePage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: any) => {
        const currentReferences = documentData?.references ?? [];
        setDocumentData({
            ...data?.position,
            references: currentReferences.map((item: any) => item.id === row.path ? {
                ...item,
                page: event.target.value
            } : item)
        });
    };

    const saveSpecificationHandler = async (updatedTenderData: any) => {
        updatePositionData(updatedTenderData);
        setSelectDocumentModal(false);
    };

    return (
        <>
            {tenderData &&
                <Dialog open={selectDocumentModal} onClose={(__, reason) => handleCloseDocumentSelectionModal(reason)}
                        className={classes.documentModal}>
                    <Typography variant="h3" className={classes.title}>Dokumentenübersicht</Typography>
                    <Typography variant="h5" className={classes.title}>Bitte Referenzdokumente auswählen</Typography>
                    {tenderData?.tender?.files?.map((row: any, index: any) => (
                        <>
                            <ListItemDownload
                                id={index}
                                key={index}
                                name={parsePathFromOriginal(row.path)}
                                mime={parsePathFromOriginal(row.path)?.split(".").pop()}
                                // border
                                action={
                                    <Stack spacing={1}
                                           sx={{
                                               display: "flex",
                                               flexDirection: "row",
                                               alignItems: "center",
                                               position: "absolute",
                                               right: 0
                                           }}>
                                        {row.name.split(".").pop() === "pdf"
                                            &&
                                            <FormInput
                                                key={index}
                                                id={index}
                                                InputProps={{
                                                    placeholder: "Page number e.g: 23",
                                                    type: "number",
                                                    onChange: (event) => {
                                                        handleOnChangePage(event, row);
                                                    }
                                                }}
                                            />
                                        }
                                        <Checkbox
                                            key={index}
                                            onChange={(event) => handleCheckboxChange?.(event, row, index)}
                                        />
                                    </Stack>
                                }
                            />
                            <Divider />
                        </>
                    ))
                    }
                    <Stack direction="row" spacing={1} sx={{ display: "flex" }}>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            // disabled={!file}
                            loading={false}
                            type="submit"
                            fullWidth
                            loadingPosition="center"
                            startIcon={<CheckIcon />}
                            onClick={() => saveSpecificationHandler?.(documentData)}
                        >Abschicken</LoadingButton>
                        <Button
                            fullWidth
                            onClick={() => setSelectDocumentModal(false)}>{t("cancel")}</Button>
                    </Stack>
                </Dialog>}
            <StyledDrawer open={Boolean(posId)} onClose={handleClose} variant="persistent" anchor="right" width={700}>

                <Box sx={{ pt: 2, px: 3 }}>
                    <DialogHead title={data?.position?.pos ?? ""} onClose={handleClose} />
                </Box>
                <Stack sx={{ flexGrow: 1, overflow: "auto", ...scrollbarSmall, px: 3 }}>
                    <Breadcrumbs
                        separator={<ChevronRightIcon sx={{ fontSize: 10 }} />}
                        sx={{ mb: 2, "& .MuiBreadcrumbs-separator": { mx: 1, mt: 0.25 } }}
                    >
                        {data?.position?.levels?.map((level, key) => (
                            <Typography key={key} variant="h4" sx={{ lineHeight: "28px", color: "secondary.main" }}>
                                {level}
                            </Typography>
                        ))}
                    </Breadcrumbs>
                    <Stack sx={{ pb: 2 }}>
                        <Stack sx={{ pb: 2 }}>
                            <Typography variant="h4">Labels</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                            <Stack direction="row" spacing={0.5}>
                                <IconButton size="small" onClick={handleOpenLabelsMenu}>
                                    <PlusIcon fontSize="small" sx={{ color: "#ABB6C3" }} />
                                </IconButton>
                                <Menu
                                    anchorEl={labelsMenu}
                                    open={Boolean(labelsMenu)}
                                    onClose={handleCloseLabelsMenu}
                                    variant="menu"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 200,
                                            width: "fit-content"
                                        }
                                    }}
                                >
                                    {labels.filter(filteredItems => {
                                        return !data?.position?.keywords?.some(originalItem => originalItem.text === filteredItems.text);
                                    })?.map((label, index) => (
                                        <MenuItem key={index} onClick={() => handleLabelsMenuItemClick(label)}>
                                            {label.text}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Stack>
                            <Stack direction="row" spacing={1} sx={{ display: "block" }}>
                                {data?.position?.keywords?.map((keyword, index) => (
                                    <Chip
                                        key={`${index}-${keyword}`}
                                        size="small"
                                        label={
                                            <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "black" }}>
                                                {keyword.text}
                                            </Typography>
                                        }
                                        color="primary"
                                        variant="outlined"
                                        onDelete={() => handleDeleteLabelsChip(keyword)}
                                        sx={{
                                            ".MuiChip-label": { textTransform: "capitalize", fontWeight: 700 },
                                            height: "fit-content"
                                        }}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                        <Divider sx={{ mt: 2 }} />
                    </Stack>
                    <Stack sx={{ pb: 2 }}>
                        <Stack sx={{ pb: 2 }}>
                            <Typography variant="h4">Kapitel</Typography>
                        </Stack>
                        <Stack direction="row" spacing={7}>
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                <IconButton size="small" onClick={handleOpenChapterMenu}>
                                    <PlusIcon fontSize="small" sx={{ color: "#ABB6C3" }} />
                                </IconButton>
                                <Menu
                                    anchorEl={chapterMenu}
                                    open={Boolean(chapterMenu)}
                                    onClose={handleCloseChapterMenu}
                                    variant="menu"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 200,
                                            width: "fit-content"
                                        }
                                    }}
                                >
                                    {levels.map((level, index) => (
                                        <MenuItem key={index} onClick={() => handleChapterMenuItemClick(level)}>
                                            {level.slice(-1)[0]}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <Chip
                                    size="small"
                                    label={
                                        <Breadcrumbs
                                            separator={<ChevronRightIcon sx={{ fontSize: 10 }} />}
                                            sx={{ textTransform: "capitalize" }}
                                        >
                                            {data?.position?.levels?.map((level, key) => (
                                                <Typography key={key}
                                                            sx={{
                                                                fontWeight: "bold",
                                                                fontSize: "14px",
                                                                color: "black"
                                                            }}>
                                                    {level}
                                                </Typography>
                                            ))}
                                        </Breadcrumbs>
                                    }
                                    color="primary"
                                    sx={{ height: "fit-content" }}
                                    variant="outlined"

                                />
                            </Stack>
                        </Stack>
                        <Divider sx={{ mt: 2 }} />
                    </Stack>
                    <Stack sx={{ pb: 2 }}>
                        <Stack direction="row" sx={{ pb: 1 }}>
                            <Typography variant="h4">Add Reference Documents</Typography>
                            <IconButton size="small" onClick={() => setSelectDocumentModal(true)}>
                                <PlusIcon fontSize="small" sx={{ color: "#ABB6C3" }} />
                            </IconButton>
                        </Stack>

                        <Divider sx={{ mt: 2 }} />
                    </Stack>
                    <PositionDetailsGroup>
                        <HtmlContent html={data?.position?.description} />
                        {Boolean(data?.position?.subitems?.length) && (
                            <List disablePadding>
                                {data?.position?.subitems?.map((item) => (
                                    <ListItem
                                        key={item.id}
                                        sx={{
                                            borderLeft: "4px solid",
                                            borderColor: "secondary.main",
                                            my: 1.5,
                                            p: 1,
                                            py: 0,
                                            fontSize: 14
                                        }}
                                    >
                                        {parseHTML(item?.description)}
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </PositionDetailsGroup>
                    {Boolean(data?.position?.specifications) && (
                        <PositionDetailsGroup label="Vorgaben">
                            <HtmlContent html={data?.position?.specifications} />
                        </PositionDetailsGroup>
                    )}
                    {Boolean(data?.position?.referencedPositions?.length) && (
                        <PositionDetailsGroup label="Referenzierte Lasten">
                            {data?.position?.referencedPositions?.map((item) => (
                                <ListItemWrapper key={item.id} border>
                                    <Stack sx={{ p: 1 }}>
                                        <Typography variant="h5">{item.pos}</Typography>
                                        <Typography variant="body2" sx={{ pr: 2 }} component="div"
                                                    className="html-content">
                                            {parseHTML(item?.description)}
                                        </Typography>
                                        <Typography variant="body2" sx={{ pr: 2 }} component="div"
                                                    className="html-content">
                                            {parseHTML(item?.specifications)}
                                        </Typography>
                                    </Stack>
                                </ListItemWrapper>
                            ))}
                        </PositionDetailsGroup>
                    )}
                    {Boolean(referencesDocuments.length) && (
                        <PositionDetailsGroup label="Referenzierte Dokumente">
                            <Stack>
                                {referencesDocuments.map((reference) => (
                                    <ListItemDownload
                                        id={reference.id}
                                        name={reference.name}
                                        mime={reference.name.split(".").pop()}
                                        page={reference.page}
                                        border
                                        action={
                                            <>
                                                {reference.name.split(".").pop() === "pdf" ? (
                                                    <Button
                                                        onClick={() =>
                                                            setDoc({
                                                                title: reference.name,
                                                                subTitle: "",
                                                                src: reference.id
                                                            })
                                                        }
                                                        startIcon={<ArrowRightIcon color="secondary" />}
                                                    >
                                                        {t("open_file")}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            window.open(reference.id, "_blank")
                                                        }
                                                    >
                                                        {"Download"}
                                                    </Button>
                                                )}
                                            </>

                                        }
                                    />
                                ))}
                            </Stack>
                        </PositionDetailsGroup>
                    )}
                </Stack>
            </StyledDrawer>
        </>
    );
};

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "width" })<{
    width: number;
}>(({ theme, width, open }) => ({
    flexShrink: 0,
    width: open ? width : 0,
    zIndex: 1301,
    [theme.breakpoints.up("md")]: {
        zIndex: 1099
    },
    "& .MuiDrawer-paper": {
        width,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        overflowY: "visible",
        borderRight: "none",
        zIndex: 1099,
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: 16,
            paddingRight: 0,
            paddingLeft: 0
        }
    }
}));

export default PositionDetails;