// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRightIcon, CheckIcon, DeleteIcon, EditIcon } from "../../../components/icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";
import useDatetime from "../../../hooks/usedatetime";
import Skeleton from "@mui/material/Skeleton";
import GroupAttr from "../../../components/GroupAttr";
import UserAvatar from "../../../components/UserAvatar";
import IconButton from "@mui/material/IconButton";
import { getToken } from "../../../lib/urql";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "../../../components/dialog/Dialog";
import FormInput from "../../../components/inputs/FormInput";
import DialogHead from "../../../components/dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";

type Props = {
    id: string;
    name: string;
    status?: string;
    created?: string;
    modified?: string;
    customer?: string;
    eventNo?: string;
    totalDocs?: number;
    tender?: any;
    refetchTender?: any;
};

const ProjectItem: React.FC<Props> = ({ id, name, eventNo, created, totalDocs, customer, tender, refetchTender }) => {
    const { t } = useTranslation();
    const { fDate } = useDatetime();
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [value, setValue] = useState(tender);
    const onDeleteHandler = async (id: string, name: string) => {
        try {
            setFetching(true);
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/specification/delete`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/Json"
                },
                body: JSON.stringify({ id, name })
            });
            if (response.ok) {
                setFetching(false);
                setConfirmModal(false);
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setFetching(false);
        }
    };
    const updatePositionData = async (item: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/projects/update`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/Json"
                },
                body: JSON.stringify(item)
            });
            if (response) {
                setEditProjectModal(false);
                refetchTender();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Stack component={Paper} direction={{
            xs: "column", sm: "row"
        }} sx={{
            px: 3, py: 2, "& .MuiIconButton-root": {
                display: "none"
            }, "&:hover": {
                "& .MuiIconButton-root": {
                    display: "flex",
                    alignItems: "center"
                },
                cursor: "pointer",
                backgroundColor: "#efeded"
            }
        }}>
            <Dialog open={confirmModal} onClose={() => setConfirmModal(false)}>
                <Stack>
                    <Typography sx={{ padding: "20px" }}>{t("delete_project_modal_message")}</Typography>
                </Stack>
                <Stack>
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        // disabled={!file}
                        onClick={() => onDeleteHandler(id, name)}
                        loading={fetching}
                        type="submit"
                        loadingPosition="center"
                        startIcon={<CheckIcon />}
                    >
                        {t("delete_project_modal_confirm")}
                    </LoadingButton>
                    <Button onClick={() => setConfirmModal(false)}>{t("delete_project_modal_cancel")}</Button>
                </Stack>
            </Dialog>
            <Dialog open={editProjectModal} onClose={() => setEditProjectModal(false)}>
                <DialogHead title={t("change_metadata_title")} />
                <DialogContent>
                    <Stack>
                        <FormInput
                            id="test"
                            label="Publish Date"
                            InputProps={{
                                value: value.created,
                                onChange: (event) => setValue({ ...value, created: event.currentTarget.value }),
                                type: "date"
                            }}
                        />
                        <FormInput
                            id="test"
                            label="Town/Region"
                            InputProps={{
                                value: value.customer,
                                onChange: (event) => setValue({ ...value, customer: event.currentTarget.value })
                            }}
                        />
                        <FormInput
                            id="test"
                            label="LPA number"
                            InputProps={{
                                value: value.eventNo,
                                onChange: (event) => setValue({ ...value, eventNo: event.currentTarget.value })
                            }}
                        />
                    </Stack>
                </DialogContent>

                <Stack>
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        // disabled={!file}
                        onClick={() => updatePositionData(value)}
                        loading={fetching}
                        type="submit"
                        loadingPosition="center"
                        startIcon={<CheckIcon />}
                    >
                        {t("delete_project_modal_confirm")}
                    </LoadingButton>
                    <Button onClick={() => setEditProjectModal(false)}>{t("delete_project_modal_cancel")}</Button>
                </Stack>
            </Dialog>
            <Stack sx={{ flexGrow: 1 }} onClick={(event) => {
                event.stopPropagation();
                navigate(id);
            }}>
                <Typography variant="h3" gutterBottom>
                    {name}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={3} sx={{ mb: 2 }}>
                    <Typography color="textSecondary">
                        {customer} • {eventNo}
                    </Typography>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0.5, md: 3 }}
                       sx={{ mt: "auto", height: "40px" }}>
                    <GroupAttr label={t("total_documents")} value={String(totalDocs)} minWidth="auto" />
                    <GroupAttr label={t("created")} value={fDate(created)} minWidth="auto" />
                    <GroupAttr label={t("created_by")} value={<UserAvatar />} minWidth="auto" />
                    <IconButton sx={{ width: "fit-content", marginTop: "10px" }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setEditProjectModal(true);
                                }}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton sx={{ width: "fit-content", marginTop: "10px" }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setConfirmModal(true);
                                }}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack direction="row-reverse" sx={{ flexDirection: "column", alignItems: "center" }}>
                <Button component={Link} to={id} startIcon={<ArrowRightIcon color="secondary" />}>
                    {t("show_details")}
                </Button>

            </Stack>
        </Stack>
    );
};

export const ProjectItemLoader: React.FC = () => {
    return (
        <Stack component={Paper} direction={{ xs: "column", sm: "row" }} sx={{ px: 3, py: 2, minHeight: 157 }}>
            <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="h3" width="70%" gutterBottom>
                    <Skeleton />
                </Typography>
                <Skeleton variant="rectangular" width={140} height={20} sx={{ borderRadius: 1 }} />
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0.5, md: 3 }} sx={{ mt: "auto" }}>
                    <Skeleton variant="rectangular" width={140} height={20} />
                    <Skeleton variant="rectangular" width={140} height={20} />
                    <Skeleton variant="rectangular" width={140} height={20} />
                </Stack>
            </Stack>
            <Stack direction="row-reverse">
                <Skeleton variant="rectangular" width={140} height={40} />
            </Stack>
        </Stack>
    );
};

export default ProjectItem;
