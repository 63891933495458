// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const HomeView: React.FC = () => {
    return (
        <Container sx={{ mt: 4 }}>
            <Typography sx={{ mb: 4 }} variant="h3">
                What are you looking for?
            </Typography>
            <Box display="flex">Start Page</Box>
        </Container>
    );
};

export default HomeView;
