// Copyright (C) 2022 TANNER AG

import React, { ChangeEvent, useState } from "react";
import Button from "@mui/lab/LoadingButton";
import { AddCircleIcon, CheckIcon } from "../../../components/icons/MANIcons";
import Dialog from "../../../components/dialog/Dialog";
import TenderUploadForm from "./TenderUploadForm";
import DialogHead from "../../../components/dialog/DialogHead";
import useTranslation from "../../../hooks/usetranslation";
import { getToken } from "../../../lib/urql";
import LoadingButton from "@mui/lab/LoadingButton";

import { Tender } from "../../../graphql";
import { DocumentPopupSelection } from "../hooks/DocumentPopupSelection";

const TenderCreation = () => {
    const [fetching, setFetching] = useState(false);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [openSpecification, setOpenSpecification] = useState(false);
    const [specificationData, setSpecificationData] = useState<Tender>();

    const onSubmit = async (file: File, projectName: string) => {
        try {
            setFetching(true);
            const body = new FormData();
            body.set("file", file);
            body.set("projectName", projectName);
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/file/upload`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
                body
            });
            const lastTenderData = await response.json();
            if (response.ok) {
                setFetching(false);
                setOpen(false);
                setOpenSpecification(true);
                setSpecificationData(lastTenderData);
                // window.location.reload();

            } else {
                setError(true);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setFetching(false);
        }
    };

    const saveSpecificationHandler = async (updatedTenderData: any) => {
        try {
            setFetching(true);
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/specification/save`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/Json"
                },
                body: JSON.stringify(updatedTenderData)
            });
            if (response.ok) {
                setFetching(false);
                setOpenSpecification(false);
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };


    const handleClose = (reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
            return;
        } else {
            setOpen(false);
            setOpenSpecification(false);
        }
    };
    const buttonCloseHandler = () => {
        setOpen(false);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, row: any) => {
        const parseFileName = row.name.split(".");
        const parsedFileLastItem = parseFileName.slice(-1)[0];
        const checked = event.target.checked;
        if (specificationData) {
            let initSpecificationData = specificationData;
            switch (parsedFileLastItem) {
                case "docx":
                case "doc":
                case "xlsx":
                    initSpecificationData["files"] = specificationData.files.map(item => {
                        if (item.path === row.path && checked) {
                            return { ...item, specification: `${row.name}.xml` };
                        } else if (item.path === row.path && !checked) {
                            return { ...item, specification: undefined };
                        } else {
                            return item;
                        }
                    });
                    break;
                case "pdf":
                    initSpecificationData["files"] = specificationData.files.map(item => {
                        if (item.path === row.path && checked) {
                            return { ...item, specification: `${row.name}.xhtml` };
                        } else if (item.path === row.path && !checked) {
                            return { ...item, specification: undefined };
                        } else {
                            return item;
                        }
                    });
                    break;

            }
            setSpecificationData(initSpecificationData);
        }
    };


    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="secondary"
                startIcon={<AddCircleIcon />}
                sx={{ ml: "auto" }}
            >
                {t("create_tender")}
            </Button>
            <Dialog open={open} onClose={(__, reason) => handleClose(reason)}>
                <TenderUploadForm
                    onSubmit={onSubmit}
                    head={<DialogHead title={t("create_tender")} onClose={buttonCloseHandler} />}
                    error={error}
                    setError={setError}
                >
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        // disabled={!file}
                        loading={fetching}
                        type="submit"
                        loadingPosition="center"
                        startIcon={<CheckIcon />}
                    >
                        Ausschreibung anlegen
                    </LoadingButton>
                    <Button onClick={buttonCloseHandler}>{t("cancel")}</Button>
                </TenderUploadForm>
            </Dialog>
            {specificationData && <DocumentPopupSelection specificationData={specificationData} open={openSpecification}
                                                          closeModalHandler={handleClose} fetching={fetching}
                                                          changeCheckboxHandler={handleCheckboxChange}
                                                          saveSpecificationData={saveSpecificationHandler}
            />


            }
        </>
    );
};

export default TenderCreation;
