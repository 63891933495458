// Copyright (C) 2022 TANNER AG

import create from "zustand";
import { persist } from "zustand/middleware";
import { PaletteMode } from "@mui/material";

type RuntimeStore = {
    themeMode: PaletteMode;
    locale?: string;
    country?: string;
    doc?: { title: string; subTitle: string; src: string };
    assignMode?: boolean;
    language?: string;
    search?: {
        query?: string;
        page?: string;
    };
    setLanguage(language: string): void;
    setLocale(locale: string): void;
    setCountry(country: string): void;
    setThemeMode(themeMode: PaletteMode): void;
    setDoc(doc?: { title: string; subTitle: string; src: string }): void;
    setAssignMode(assignMode?: boolean): void;
    setSearchQuery(query: string): void;
    setSearchPage(page: string): void;
};

const useStore = create<RuntimeStore>()(
    persist(
        (set) => ({
            themeMode: "light",
            setLanguage: (language: string) => set(() => ({ language })),
            setLocale: (locale) => set(() => ({ locale })),
            setCountry: (country) => set(() => ({ country })),
            setThemeMode: (themeMode) => set(() => ({ themeMode })),
            setDoc: (doc) => set(() => ({ doc })),
            setAssignMode: (assignMode) => set(() => ({ assignMode })),
            setSearchQuery: (query) => set(() => ({ search: { query, page: "1" } })),
            setSearchPage: (page) => set((state) => ({ search: { ...state.search, page } }))
        }),
        {
            name: "man-c42",
            partialize: (state) => ({
                locale: state.locale,
                country: state.country,
                themeMode: state.themeMode
            })
        }
    )
);

export default useStore;
