// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useUserQuery } from "../graphql";
import { UserIcon } from "./icons/MANIcons";

const UserAvatar: React.FC = () => {
    const [{ data }] = useUserQuery();

    return (
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={0.5}>
            <Avatar sx={{ width: 24, height: 24 }}>
                <UserIcon />
            </Avatar>
            <Typography variant="body2" color="textSecondary">
                {data?.user?.name || data?.user?.username}
            </Typography>
        </Stack>
    );
};

export default UserAvatar;
