// Copyright (C) 2022 TANNER AG

import useStore from "./usestore";
import shallow from "zustand/shallow";
import { useMemo } from "react";
import { TRANSLATION } from "../lib/translation";

const useLocale = () => {
    const [locale, setLocale] = useStore((state) => [state.locale, state.setLocale], shallow);
    const [country, setCountry] = useStore((state) => [state.country, state.setCountry], shallow);
    const options = useMemo(() => {
        return TRANSLATION[locale ?? "de"];
    }, [locale]);

    return {
        locale: locale ?? "de",
        setLocale,
        country: country ?? "DE",
        setCountry,
        locales: ["de", "en"],
        ...options
    };
};

export default useLocale;
