// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useTranslation from "../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import TenderListItem from "./TenderListItem";
import { useTendersQuery } from "../../../graphql";
import TenderCreation from "../creation/TenderCreation";

const TenderOverview: React.FC = () => {
    const { t } = useTranslation();
    const [{ data }, refetch] = useTendersQuery({ requestPolicy: "network-only" });

    return (
        <Container sx={{ mt: 4 }}>
            <Stack direction="row" sx={{ alignItems: "center", mb: 4 }}>
                <Typography variant="h2">{t("tenders")}</Typography>
                <TenderCreation />
            </Stack>
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                {t("total_tenders", { total: data?.tenders?.length ?? "..." })}
            </Typography>
            <Stack spacing={2}>
                {data?.tenders?.map((tender) => (
                    <TenderListItem
                        tender={tender}
                        key={tender.id}
                        id={tender.id}
                        name={tender.name}
                        created={tender.created}
                        modified={tender.modified}
                        customer={tender.customer}
                        eventNo={tender.eventNo}
                        totalDocs={tender.fileQuantity}
                        refetchTender={refetch}
                    />
                ))}
            </Stack>
        </Container>
    );
};

export default TenderOverview;
