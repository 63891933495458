// Copyright (C) 2022 TANNER AG

import React from "react";
import MuiPaginationItem from "@mui/material/PaginationItem";
import MuiPagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import useParam from "../../../../hooks/useparam";

const RESULTS_PER_PAGE = 10;

type Props = {
    total?: number;
};

const Pagination: React.FC<Props> = ({ total = 0 }) => {
    const [value, setValue] = useParam("page", "1");
    return (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <MuiPagination
                color="primary"
                shape="rounded"
                page={Number(value)}
                count={Math.ceil(total / RESULTS_PER_PAGE)}
                onChange={(_: unknown, page: number) => setValue(String(page))}
                renderItem={(item) => <MuiPaginationItem {...item} />}
            />
        </Box>
    );
};

export default Pagination;
