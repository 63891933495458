// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BackButton from "../../../components/BackButton";
import { useTenderQuery } from "../../../graphql";
import useSidebarLevels from "../hooks/usesidebarlevels";
import Sidebar from "./positions/Sidebar";
import { Box, SelectChangeEvent } from "@mui/material";
import FormSelect from "../../../components/inputs/FormSelect";
import { TurnedInNotOutlined } from "@mui/icons-material";
import usePositions from "../hooks/usepositions";
import PositionFilterLanguage from "./positions/PositionFilterLanguage";

type Props = {
    id: string;
    setFetchingPosition: any;
};


const TenderSidebar: React.FC<Props> = ({ id, setFetchingPosition }) => {
    const [{ data: tenderData }] = useTenderQuery({ variables: { id } });
    const { data, specifications, refetchSidebar }: any = useSidebarLevels(id);
    const [selected, setSelected] = useState<string>("");
    const selectedValue = JSON.parse(localStorage.getItem(id) || "{}").specification ?? specifications[0]?.dbpath;
    const { refetchPosition } = usePositions(id);

    const refetchAllData = () => {
        refetchPosition();
        refetchSidebar();
    };
    const handleChange = (event: SelectChangeEvent<string>) => {
        if (event.target.value === "label") {
            localStorage.setItem(id, JSON.stringify({ specification: specifications[0]?.dbpath }));
        } else {
            localStorage.setItem(id, JSON.stringify({ specification: event.target.value }));
        }
        setSelected(event.target.value);
        setFetchingPosition(true);
        refetchAllData();
    };

    useEffect(() => {
        if (specifications[0]) {
            localStorage.setItem(id, JSON.stringify({ specification: specifications[0]?.dbpath }));
        }
        setFetchingPosition(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!localStorage.getItem(id) && !specifications[0]]);

    useEffect(() => {
        const lastSelected = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "label";
        setSelected(lastSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <HeaderContainer width={400} open={true}>
                <Stack className="back-button" direction="row">
                    <BackButton to="/tenders" />
                </Stack>
            </HeaderContainer>
            <StyledDrawer variant="persistent" width={400} anchor="left" open={true}>

                <Stack sx={{ mb: 4 }}>
                    <Typography variant="h4">{tenderData?.tender?.name}</Typography>
                    <Typography variant="h5" color="textSecondary">
                        {tenderData?.tender?.customer} • {tenderData?.tender?.eventNo}
                    </Typography>
                </Stack>
                <Stack
                    sx={{
                        "& .MuiFormControl-root": { margin: 0 },
                        "& .MuiOutlinedInput-root": { width: "100% !important" },
                        "& .MuiSelect-select": { width: "75%", textOverflow: "ellipsis" }
                    }}>
                    <Typography variant="h5" color="textSecondary">
                        {"Select Specification Document Version"}
                    </Typography>
                    <FormSelect
                        id="specification"
                        label={{ label: "Specification", labelIcon: <TurnedInNotOutlined sx={{ mr: 1 }} /> }}
                        options={[...specifications.map((specification: any) => ({
                            label: specification.name,
                            value: specification.dbpath
                        }))]}
                        value={(!selectedValue && selected === "") ? "label" : selectedValue}
                        handleChange={handleChange}
                    />
                </Stack>
                <Stack sx={{ height: "100%" }}>
                    <Sidebar data={data} id={id} />
                </Stack>
                <Stack
                    sx={{
                        position: "absolute",
                        bottom: "5%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                    <Typography>
                        {"Sprache"}
                    </Typography>
                    <PositionFilterLanguage id={id} refetchAllData={refetchAllData} specification={selectedValue}
                                            setFetchingPosition={setFetchingPosition} sidebarLevelsData={data} />
                </Stack>
            </StyledDrawer>
        </>
    );
};

const HeaderContainer = styled(Box, { shouldForwardProp: (props) => props !== "width" })<{
    width: number;
    open: boolean;
}>(({ theme, width, open }) => ({
    display: open ? "flex" : "none",
    position: "fixed",
    height: "7vh",
    width: width,
    marginLeft: 0,
    borderRight: "1px solid #CBD3DC",
    zIndex: 1222,
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    "& .back-button": {
        position: "fixed",
        marginLeft: "24px"
    }
}));


const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "width" })<{
    width: number;
}>(({ theme, width, open }) => ({
    flexShrink: 0,
    width: open ? width : 0,
    zIndex: 1301,
    [theme.breakpoints.up("md")]: {
        zIndex: 1099
    },
    "& .MuiDrawer-paper": {
        width,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        overflowY: "visible",
        borderRight: "1px solid #CBD3DC",
        marginTop: "50px",
        zIndex: 1099,
        backgroundColor: "#EAEDF3",

        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: 16,
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3)
        }
    },
    "& .Mui-selected": {
        borderLeft: "4px solid #4B96D2"
    }
}));

export default TenderSidebar;
