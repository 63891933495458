// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import FormSelect from "../../../../components/inputs/FormSelect";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { GTranslate } from "@mui/icons-material";

type Props = {
    id: string,
    refetchAllData: any,
    setFetchingPosition: any,
    sidebarLevelsData: any,
    specification: any
};

const PositionFilterLanguage: React.FC<Props> = ({
                                                     id,
                                                     refetchAllData,
                                                     setFetchingPosition,
                                                     sidebarLevelsData,
                                                     specification
                                                 }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const language = JSON.parse(localStorage.getItem(id) || "{}").language;
    const [selectedValue, setSelected] = useState<any>(language ?? "en");
    const handleChange = (event: SelectChangeEvent<string>) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("level");
        setSearchParams(newSearchParams);
        setSelected(event.target.value);
        localStorage.setItem(id, JSON.stringify({ specification: specification, language: event.target.value }));
        refetchAllData();
    };
    const label = {
        label: "Language",
        labelIcon: <GTranslate sx={{ mr: 1 }} />
    };
    const dummyData = [{
        label: "English",
        value: "en"
    }, {
        label: "Original",
        value: "original"
    }];

    useEffect(() => {
        console.log(specification);
        if (!language && specification) {
            setSelected("en");
            localStorage.setItem(id, JSON.stringify({ specification: specification, language: "en" }));
            setFetchingPosition(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue && specification]);

    useEffect(() => {
        if (sidebarLevelsData?.length === 0 && language === "en") {
            const timer = setTimeout(() => {
                setSelected("original");
                localStorage.setItem(id, JSON.stringify({ specification: specification, language: "original" }));
                setFetchingPosition(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
        localStorage.setItem(id, JSON.stringify({ specification: specification, language: selectedValue }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarLevelsData]);
    return (
        <FormSelect
            id="language"
            label={label}
            options={dummyData}
            value={selectedValue}
            width={150}
            handleChange={handleChange}
        />
    );
};

export default PositionFilterLanguage;
