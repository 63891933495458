// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import clsx from "clsx";
import { CheckIcon } from "../icons/MANIcons";
import useBreakpoint from "../../hooks/usebreakpoint";
import ListItemRegularMobile from "./ListItemRegularMobile";
import ListItemRegularImage from "./ListItemRegularImage";

type Props = {
    title?: string;
    description?: string;
    image?: string;
    action?: React.ReactNode;
    toolbar?: React.ReactNode;
    additional?: React.ReactNode;
    border?: boolean;
    selected?: boolean;
    inactive?: boolean;
    key?: string;
    children?: React.ReactNode;
    no?: string | number;
};

const ListItemRegular: React.FC<Props> = ({
                                              title,
                                              description,
                                              image,
                                              action,
                                              toolbar,
                                              border,
                                              selected,
                                              inactive,
                                              additional,
                                              children,
                                              no
                                          }) => {
    const { down } = useBreakpoint("sm");

    return (
        <ListItemWrapper border={border} selected={selected}>
            <Stack sx={{ p: 1 }}>
                <ListItemRegularImage title={title} src={image} inactive={inactive} />
            </Stack>
            <Stack sx={{ p: 1, flexGrow: 1 }}>
                {down ? (
                    <ListItemRegularMobile
                        title={title}
                        action={action}
                        additional={additional}
                        description={description}
                        children={children}
                    />
                ) : (
                    <>
                        <Stack sx={{ mr: 6 }}>
                            <Stack direction="row">
                                {no && (
                                    <Typography
                                        variant="h4"
                                        sx={{ lineHeight: "32px", fontSize: 18, color: "secondary.main", minWidth: 40 }}
                                        className="heading"
                                        gutterBottom
                                    >
                                        {no}
                                    </Typography>
                                )}
                                <Typography
                                    variant="h4"
                                    sx={{ lineHeight: "32px", fontSize: 18, color: "inherit" }}
                                    className="heading"
                                    gutterBottom
                                >
                                    {title}
                                </Typography>
                            </Stack>
                            <Typography
                                variant="body2"
                                sx={{ pr: 2 }}
                                dangerouslySetInnerHTML={{ __html: description ?? "" }}
                                color="textSecondary"
                                component="div"
                                gutterBottom
                            />
                            {children}
                        </Stack>
                        <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mt: 0.5 }}>
                            <div>{additional}</div>
                            <div>{action}</div>
                        </Stack>
                    </>
                )}
            </Stack>
            {selected && (
                <Fab
                    color="secondary"
                    size="small"
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: { xs: 28, sm: 35 },
                        height: { xs: 28, sm: 35 },
                        minHeight: "auto",
                        userSelect: "none",
                        pointerEvents: "none"
                    }}
                >
                    <CheckIcon fontSize="small" />
                </Fab>
            )}
            {Boolean(toolbar) && (
                <Stack direction="row" spacing={1} sx={{ position: "absolute", top: { xs: 4, sm: 12 }, right: 12 }}>
                    {toolbar}
                </Stack>
            )}
        </ListItemWrapper>
    );
};

export const ListItemWrapper: React.FC<Pick<Props, "border" | "selected" | "children" | "key">> = ({
                                                                                                       border,
                                                                                                       selected,
                                                                                                       children,
                                                                                                       key
                                                                                                   }) => {
    return (
        <Stack
            component={Paper}
            direction="row"
            key={key}
            className={clsx({ border, selected })}
            sx={{
                position: "relative",
                transition: "all .3s",
                backgroundColor: selected ? "#fff" : "#F1F4FA",
                "&.border": {
                    borderBottom: "1px solid",
                    borderRadius: 0,
                    borderColor: "grey.100"
                },
                "&.selected": { border: "1px solid", borderColor: "secondary.main" }
            }}
        >
            {children}
        </Stack>
    );
};

export default ListItemRegular;
