// Copyright (C) 2022 TANNER AG

declare global {
    interface Window {
        deferredPrompt?: Event & { prompt(): void };
    }
}

export const scrollbarSmall = {
    "&::-webkit-scrollbar": {
        width: 6,
        height: 6
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: 4
    },

    "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderRadius: 4
    }
};

export const formatBytes = (bytes = 0, decimals = 2, label = true) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + (label ? " " + sizes[i] : "");
};

export const copyToClipboard = async (data: string) => {
    if (!navigator?.clipboard?.writeText) {
        return;
    }

    try {
        await navigator.clipboard.writeText(data);
    } catch (err) {
        console.log(err);
    }
};

export const compare =
    <T extends {}>(prop: keyof T, order?: "asc" | "desc") =>
    (a: T, b: T): number => {
        if (a[prop] > b[prop]) {
            return order === "desc" ? -1 : 1;
        }
        if (a[prop] < b[prop]) {
            return order === "desc" ? 1 : -1;
        }
        return 0;
    };

export const zeroPad = (num: string | number, places = 2) => String(num).padStart(places, "0");

export const formatNo = (no: (number | string | null | undefined)[]) => no.filter((n) => n).join(".");
