// Copyright (C) 2022 TANNER AG

import { usePositionsQuery } from "../../../graphql";
// import { useSearchParams } from "react-router-dom";
import useStore from "../../../hooks/usestore";


const usePositionsLevels = (id: string) => {
    const assignMode = useStore((state) => state.assignMode);
    // const [searchParams] = useSearchParams();
    const query = "";
    const level = "";
    const cat = "";
    const type = "";
    const specification = JSON.parse(localStorage.getItem(id) || "{}").specification ?? "";
    const language = JSON.parse(localStorage.getItem(id) || "{}").language ?? "";
    const [{ data, fetching, error }] = usePositionsQuery({
        variables: { where: { id, query, level, cat, assignMode, type, specification, language } }
    });

    const levels: any[] = [];
    const labels: any[] = [];
    data?.positions?.items.forEach((item) => {
        levels.push(item.levels);
        if (item.keywords) item.keywords.forEach(item => labels.push(item));
    });

    const removeDuplicates = (arr: Array<any>): Array<any> => {
        return Array.from(new Set(arr.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));
    };

    // console.log(removeDuplicates(labels));

    const dummyLabels = [
        {
            "key": "",
            "text": "Vertriebstechnik",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "After Sales",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Transport Solutions",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Tender Manager",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "SBC Country Manager",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Marge & Volume",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "R. Staimer",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "After Sales Management AREA EUROPE",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Absatzfinan-zierung",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Controlling",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Buy Back",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Rechts-beratung",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Delivery Plan",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Electrics / Electronics",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Governance, Risk & Compliance",
            "__typename": "Keyword"
        },
        {
            "key": "",
            "text": "Nachhaltig-keit",
            "__typename": "Keyword"
        }
    ];

    const generateHierarchy = (arr: string[][]): string[][] => {
        const hierarchy: string[][] = [];

        arr.forEach((item) => {
            let currentPath: string[] = [];
            item.forEach((element) => {
                currentPath.push(element);
                hierarchy.push([...currentPath]);
            });
        });

        return hierarchy;
    };

    const transformedArrayOfArrays = generateHierarchy(removeDuplicates(levels));

    return {
        levels: removeDuplicates(transformedArrayOfArrays) ?? [],
        labels: dummyLabels,
        fetching,
        error
    };
};

export default usePositionsLevels;
