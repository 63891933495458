import gql from "graphql-tag";
import * as Urql from "urql";

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends {
    [key: string]: unknown
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    JSON: any;
};

export type ApplicableDoc = {
    __typename?: "ApplicableDoc";
    id: Scalars["ID"];
    pdf?: Maybe<Scalars["String"]>;
    refid?: Maybe<Scalars["String"]>;
    src?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    type: Scalars["String"];
};

export type Cart = {
    __typename?: "Cart";
    items: Array<CartItem>;
    name: Scalars["ID"];
};

export type CartItem = {
    __typename?: "CartItem";
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    partnr: Scalars["String"];
    qty: Scalars["Int"];
};

export type CartItemDataInput = {
    name?: InputMaybe<Scalars["String"]>;
    note?: InputMaybe<Scalars["String"]>;
    partnr: Scalars["String"];
    qty: Scalars["Int"];
};

export type CartItemWhereInput = {
    name: Scalars["String"];
};

export type CartWhereInput = {
    name: Scalars["String"];
};

export type CategoryDataInput = {
    name: Scalars["String"];
};

export type Content = {
    __typename?: "Content";
    html?: Maybe<Scalars["String"]>;
};

export type DocInfo = {
    __typename?: "DocInfo";
    applicableDocs?: Maybe<Array<ApplicableDoc>>;
    id: Scalars["ID"];
    oidate?: Maybe<Scalars["String"]>;
    partinfo?: Maybe<Scalars["String"]>;
    rev?: Maybe<Scalars["String"]>;
    revdate?: Maybe<Scalars["String"]>;
    revno?: Maybe<Scalars["String"]>;
    spl?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    type?: Maybe<Scalars["String"]>;
    version?: Maybe<Scalars["String"]>;
};

export type DocTocItem = {
    __typename?: "DocTocItem";
    id: Scalars["ID"];
    nodes?: Maybe<Array<DocTocItem>>;
    target: DocTocTarget;
    title: Scalars["String"];
};

export type DocTocTarget = {
    __typename?: "DocTocTarget";
    anchorid: Scalars["String"];
    docid: Scalars["String"];
};

export type DocWhereInput = {
    docId: Scalars["String"];
    partId?: InputMaybe<Scalars["String"]>;
};

export type Health = {
    __typename?: "Health";
    health?: Maybe<Scalars["Boolean"]>;
    userAgent?: Maybe<Scalars["String"]>;
};

export type InfoItem = {
    __typename?: "InfoItem";
    doctitle?: Maybe<Scalars["String"]>;
    effect?: Maybe<Array<Scalars["String"]>>;
    id: Scalars["ID"];
    matches?: Maybe<Array<InfoItemMatch>>;
    pdf?: Maybe<Scalars["String"]>;
    score?: Maybe<Scalars["String"]>;
    subtitle?: Maybe<Scalars["String"]>;
    target: InfoTarget;
    task?: Maybe<Scalars["String"]>;
    taskid?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    type?: Maybe<Scalars["String"]>;
};

export type InfoItemMatch = {
    __typename?: "InfoItemMatch";
    text: Scalars["String"];
};

export type InfoTarget = {
    __typename?: "InfoTarget";
    anchorid?: Maybe<Scalars["String"]>;
    docid?: Maybe<Scalars["String"]>;
    link?: Maybe<Scalars["String"]>;
};

export type Keyword = {
    __typename?: "Keyword";
    all?: Maybe<Scalars["String"]>;
    count?: Maybe<Scalars["String"]>;
    key?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
};

export type Mutation = {
    __typename?: "Mutation";
    addCartItem?: Maybe<Cart>;
    createCart?: Maybe<Cart>;
    createPositionCategory?: Maybe<PositionCategory>;
    deleteCart?: Maybe<Scalars["Boolean"]>;
    removeCartItem?: Maybe<Cart>;
    removePositionCategory?: Maybe<PositionCategory>;
    updateCategoryPositions?: Maybe<PositionResult>;
};


export type MutationAddCartItemArgs = {
    data: CartItemDataInput;
    where: CartItemWhereInput;
};


export type MutationCreateCartArgs = {
    where: CartWhereInput;
};


export type MutationCreatePositionCategoryArgs = {
    data: CategoryDataInput;
};


export type MutationDeleteCartArgs = {
    where: CartWhereInput;
};


export type MutationRemoveCartItemArgs = {
    partNo: Scalars["String"];
    where: CartItemWhereInput;
};


export type MutationRemovePositionCategoryArgs = {
    id: Scalars["String"];
};


export type MutationUpdateCategoryPositionsArgs = {
    id: Scalars["String"];
    mode?: InputMaybe<UpdateMode>;
    positions: Array<Scalars["String"]>;
};

export type PaginationInput = {
    limit?: InputMaybe<Scalars["Int"]>;
    start?: InputMaybe<Scalars["Int"]>;
};

export type Position = {
    __typename?: "Position";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    keywords?: Maybe<Array<Keyword>>;
    levels?: Maybe<Array<Scalars["String"]>>;
    notes?: Maybe<Scalars["String"]>;
    pos?: Scalars["String"];
    referencedPositions?: Maybe<Array<Position>>;
    references?: Maybe<Array<Reference>>;
    specifications?: Maybe<Scalars["String"]>;
    subitems?: Maybe<Array<Position>>;
    type?: Maybe<Scalars["String"]>;
};

export type PositionCategory = {
    __typename?: "PositionCategory";
    custom?: Maybe<Scalars["Boolean"]>;
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name: Scalars["String"];
    positions?: Maybe<Array<Scalars["String"]>>;
};

export type PositionFilter = {
    __typename?: "PositionFilter";
    levels: Array<Scalars["String"]>;
    types: Array<Scalars["String"]>;
};

export type PositionResult = {
    __typename?: "PositionResult";
    filter: PositionFilter;
    items: Array<Position>;
    total: Scalars["Int"];
};

export type PositionWhereInput = {
    assignMode?: InputMaybe<Scalars["Boolean"]>;
    cat?: InputMaybe<Scalars["String"]>;
    id: Scalars["String"];
    level?: InputMaybe<Scalars["String"]>;
    query?: InputMaybe<Scalars["String"]>;
    type?: InputMaybe<Scalars["String"]>;
    specification?: InputMaybe<Scalars["String"]>;
    language?: InputMaybe<Scalars["String"]>;
};

export type Query = {
    __typename?: "Query";
    cart?: Maybe<Cart>;
    carts: Array<Scalars["String"]>;
    docInfo?: Maybe<DocInfo>;
    docToc: Array<DocTocItem>;
    health?: Maybe<Health>;
    position?: Maybe<Position>;
    positionCategories: Array<PositionCategory>;
    positions?: Maybe<PositionResult>;
    search?: Maybe<SearchResult>;
    searchFilter: Array<Scalars["String"]>;
    tender?: Maybe<Tender>;
    tenders: Array<Tender>;
    user?: Maybe<User>;
};


export type QueryCartArgs = {
    where: CartWhereInput;
};


export type QueryDocInfoArgs = {
    where: DocWhereInput;
};


export type QueryDocTocArgs = {
    where: DocWhereInput;
};


export type QueryPositionArgs = {
    id: Scalars["String"];
    posId: Scalars["String"];
};


export type QueryPositionCategoriesArgs = {
    id: Scalars["String"];
};


export type QueryPositionsArgs = {
    pagination?: InputMaybe<PaginationInput>;
    where: PositionWhereInput;
};


export type QuerySearchArgs = {
    pagination?: InputMaybe<PaginationInput>;
    where: SearchWhereInput;
};


export type QuerySearchFilterArgs = {
    field: Scalars["String"];
    where: SearchWhereInput;
};


export type QueryTenderArgs = {
    id: Scalars["String"];
};

export type Reference = {
    __typename?: "Reference";
    id: Scalars["String"];
    name: Scalars["String"];
    type?: Maybe<Scalars["String"]>;
};

export type SearchResult = {
    __typename?: "SearchResult";
    items: Array<InfoItem>;
    total: Scalars["Int"];
};

export type SearchWhereInput = {
    doc?: InputMaybe<Array<Scalars["String"]>>;
    effect?: InputMaybe<Array<Scalars["String"]>>;
    query?: InputMaybe<Scalars["String"]>;
    task?: InputMaybe<Array<Scalars["String"]>>;
};

export type TenderSingleFileType = {
    name: Scalars["String"];
    path: Scalars["String"];
    specification?: Scalars["String"]
}

export type Tender = {
    __typename?: "Tender";
    created?: Scalars["String"];
    customer: Scalars["String"];
    eventNo: Scalars["String"];
    id: Scalars["ID"];
    modified?: Scalars["String"];
    name: Scalars["String"];
    files: Array<TenderSingleFileType>;
    fileQuantity?: Scalars["Int"];
};

export enum UpdateMode {
    Remove = "remove",
    Update = "update"
}

export type User = {
    __typename?: "User";
    name?: Maybe<Scalars["String"]>;
    username: Scalars["String"];
};

export type AddCartItemMutationVariables = Exact<{
    where: CartItemWhereInput;
    data: CartItemDataInput;
}>;


export type AddCartItemMutation = {
    __typename?: "Mutation",
    addCartItem?: {
        __typename?: "Cart",
        name: string,
        items: Array<{
            __typename?: "CartItem",
            partnr: string,
            qty: number,
            name?: string | undefined,
            note?: string | undefined
        }>
    } | undefined
};

export type CreateCartMutationVariables = Exact<{
    where: CartWhereInput;
}>;


export type CreateCartMutation = {
    __typename?: "Mutation",
    createCart?: {
        __typename?: "Cart",
        name: string,
        items: Array<{
            __typename?: "CartItem",
            partnr: string,
            qty: number,
            name?: string | undefined,
            note?: string | undefined
        }>
    } | undefined
};

export type CreateCategoryMutationVariables = Exact<{
    data: CategoryDataInput;
}>;


export type CreateCategoryMutation = {
    __typename?: "Mutation",
    createPositionCategory?: {
        __typename?: "PositionCategory",
        id: string,
        name: string,
        icon?: string | undefined,
        custom?: boolean | undefined
    } | undefined
};

export type RemoveCartItemMutationVariables = Exact<{
    where: CartItemWhereInput;
    partNo: Scalars["String"];
}>;


export type RemoveCartItemMutation = {
    __typename?: "Mutation",
    removeCartItem?: {
        __typename?: "Cart",
        name: string,
        items: Array<{
            __typename?: "CartItem",
            partnr: string,
            qty: number,
            name?: string | undefined,
            note?: string | undefined
        }>
    } | undefined
};

export type RemoveCategoryMutationVariables = Exact<{
    id: Scalars["String"];
}>;


export type RemoveCategoryMutation = {
    __typename?: "Mutation",
    removePositionCategory?: {
        __typename?: "PositionCategory",
        id: string,
        name: string,
        icon?: string | undefined,
        custom?: boolean | undefined
    } | undefined
};

export type UpdateCategoryPositionsMutationVariables = Exact<{
    id: Scalars["String"];
    positions: Array<Scalars["String"]> | Scalars["String"];
    mode?: InputMaybe<UpdateMode>;
}>;


export type UpdateCategoryPositionsMutation = {
    __typename?: "Mutation",
    updateCategoryPositions?: {
        __typename?: "PositionResult",
        total: number,
        items: Array<{
            __typename?: "Position",
            id: string
        }>
    } | undefined
};

export type CartQueryVariables = Exact<{
    where: CartWhereInput;
}>;


export type CartQuery = {
    __typename?: "Query",
    cart?: {
        __typename?: "Cart",
        name: string,
        items: Array<{
            __typename?: "CartItem",
            name?: string | undefined,
            qty: number,
            partnr: string,
            note?: string | undefined
        }>
    } | undefined
};

export type CartsQueryVariables = Exact<{
    [key: string]: never;
}>;


export type CartsQuery = {
    __typename?: "Query",
    carts: Array<string>
};

export type PositionCategoriesQueryVariables = Exact<{
    id: Scalars["String"];
}>;


export type PositionCategoriesQuery = {
    __typename?: "Query",
    positionCategories: Array<{
        __typename?: "PositionCategory",
        id: string,
        name: string,
        icon?: string | undefined,
        custom?: boolean | undefined,
        positions?: Array<string> | undefined
    }>
};

export type DocInfoQueryVariables = Exact<{
    where: DocWhereInput;
}>;


export type DocInfoQuery = {
    __typename?: "Query",
    docInfo?: {
        __typename?: "DocInfo",
        id: string,
        title: string,
        type?: string | undefined,
        rev?: string | undefined,
        revdate?: string | undefined,
        revno?: string | undefined,
        spl?: string | undefined,
        version?: string | undefined,
        applicableDocs?: Array<{
            __typename?: "ApplicableDoc",
            id: string,
            title: string,
            type: string,
            status?: string | undefined,
            refid?: string | undefined,
            src?: string | undefined
        }> | undefined
    } | undefined
};

export type DocTocQueryVariables = Exact<{
    where: DocWhereInput;
}>;


export type DocTocQuery = {
    __typename?: "Query",
    docToc: Array<{
        __typename?: "DocTocItem",
        id: string,
        title: string,
        target: {
            __typename?: "DocTocTarget",
            docid: string,
            anchorid: string
        },
        nodes?: Array<{
            __typename?: "DocTocItem",
            id: string,
            title: string,
            target: {
                __typename?: "DocTocTarget",
                docid: string,
                anchorid: string
            },
            nodes?: Array<{
                __typename?: "DocTocItem",
                id: string,
                title: string,
                target: {
                    __typename?: "DocTocTarget",
                    docid: string,
                    anchorid: string
                },
                nodes?: Array<{
                    __typename?: "DocTocItem",
                    id: string,
                    title: string,
                    target: {
                        __typename?: "DocTocTarget",
                        docid: string,
                        anchorid: string
                    }
                }> | undefined
            }> | undefined
        }> | undefined
    }>
};

export type HealthQueryVariables = Exact<{
    [key: string]: never;
}>;


export type HealthQuery = {
    __typename?: "Query",
    health?: {
        __typename?: "Health",
        health?: boolean | undefined,
        userAgent?: string | undefined
    } | undefined
};

export type PositionQueryVariables = Exact<{
    id: Scalars["String"];
    posId: Scalars["String"];
    specification?: Scalars["String"];
    language?: Scalars["String"];
}>;


export type PositionQuery = {
    __typename?: "Query",
    position?: {
        __typename?: "Position",
        id: string,
        description?: string | undefined,
        type?: string | undefined,
        notes?: string | undefined,
        pos: string,
        levels?: Array<string> | undefined,
        specifications?: string | undefined,
        references?: Array<{
            __typename?: "Reference",
            id: string,
            name: string,
            type?: string | undefined,
            page?: string,
        }> | undefined,
        subitems?: Array<{
            __typename?: "Position",
            id: string,
            description?: string | undefined,
            type?: string | undefined,
            notes?: string | undefined,
            specifications?: string | undefined,
            references?: Array<{
                __typename?: "Reference",
                id: string,
                name: string,
                type?: string | undefined
            }> | undefined,
            keywords?: Array<{
                __typename?: "Keyword",
                key?: string | undefined,
                text?: string | undefined
            }> | undefined
        }> | undefined,
        keywords?: Array<{
            __typename?: "Keyword",
            key?: string | undefined,
            text?: string | undefined
        }> | undefined,
        referencedPositions?: Array<{
            __typename?: "Position",
            id: string,
            description?: string | undefined,
            type?: string | undefined,
            notes?: string | undefined,
            pos: string,
            levels?: Array<string> | undefined,
            specifications?: string | undefined
        }> | undefined
    } | undefined
};

export type PositionsQueryVariables = Exact<{
    where: PositionWhereInput;
    pagination?: PaginationInput;
}>;


export type PositionsQuery = {
    __typename?: "Query",
    positions?: {
        __typename?: "PositionResult",
        total: number,
        filter: {
            __typename?: "PositionFilter",
            levels: Array<string>,
            types: Array<string>
        },
        items: Array<{
            __typename?: "Position",
            id: string,
            description?: string | undefined,
            type?: string | undefined,
            notes?: string | undefined,
            pos: string,
            levels?: Array<string> | undefined,
            specifications?: string | undefined,
            references?: Array<{
                __typename?: "Reference",
                id: string,
                name: string,
                type?: string | undefined
            }> | undefined,
            subitems?: Array<{
                __typename?: "Position",
                id: string,
                description?: string | undefined,
                type?: string | undefined,
                notes?: string | undefined,
                specifications?: string | undefined,
                references?: Array<{
                    __typename?: "Reference",
                    id: string,
                    name: string,
                    type?: string | undefined
                }> | undefined,
                keywords?: Array<{
                    __typename?: "Keyword",
                    key?: string | undefined,
                    text?: string | undefined
                }> | undefined
            }> | undefined,
            keywords?: Array<{
                __typename?: "Keyword",
                key?: string | undefined,
                text?: string | undefined
            }> | undefined
        }>
    } | undefined
};

export type SidebarLevelsQuery = {
    __typename?: "Query",
    positions?: {
        __typename?: "SidebarLevels",
        filter: {
            __typename?: "PositionFilter",
            levels: Array<string>,
            types: Array<string>
        }
        specifications: {
            name: Scalars["String"];
            path: Scalars["String"];
            dbpath: Scalars["String"];
            specification?: Scalars["String"];
        }
    } | undefined
};

export type SearchQueryVariables = Exact<{
    where: SearchWhereInput;
    pagination: PaginationInput;
}>;


export type SearchQuery = {
    __typename?: "Query",
    search?: {
        __typename?: "SearchResult",
        total: number,
        items: Array<{
            __typename?: "InfoItem",
            id: string,
            title: string,
            subtitle?: string | undefined,
            type?: string | undefined,
            score?: string | undefined,
            effect?: Array<string> | undefined,
            task?: string | undefined,
            taskid?: string | undefined,
            doctitle?: string | undefined,
            pdf?: string | undefined,
            target: {
                __typename?: "InfoTarget",
                docid?: string | undefined,
                anchorid?: string | undefined,
                link?: string | undefined
            },
            matches?: Array<{
                __typename?: "InfoItemMatch",
                text: string
            }> | undefined
        }>
    } | undefined
};

export type SearchFilterQueryVariables = Exact<{
    where: SearchWhereInput;
    field: Scalars["String"];
}>;


export type SearchFilterQuery = {
    __typename?: "Query",
    searchFilter: Array<string>
};

export type TenderQueryVariables = Exact<{
    id: Scalars["String"];
}>;


export type TenderQuery = {
    __typename?: "Query",
    tender?: {
        __typename?: "Tender",
        id: string,
        name: string,
        customer: string,
        eventNo: string,
        modified?: string,
        created?: string
        files: Array<{
            name?: Scalars["String"];
            path?: Scalars["String"];
            dbpath?: Scalars["String"];
            specification?: Scalars["String"];
            fileUrl?: Scalars["String"];
        }>
    } | undefined
};

export type TendersQueryVariables = Exact<{
    [key: string]: never;
}>;


export type TendersQuery = {
    __typename?: "Query",
    tenders: Array<{
        __typename?: "Tender",
        id: string,
        name: string,
        customer: string,
        eventNo: string,
        modified: string,
        created: string,
        fileQuantity: number
        files: Array<{
            name: string,
            path: string,
            specification: string,
        }>
    }>
};

export type UserQueryVariables = Exact<{
    [key: string]: never;
}>;


export type UserQuery = {
    __typename?: "Query",
    user?: {
        __typename?: "User",
        username: string,
        name?: string | undefined
    } | undefined
};


export const AddCartItemDocument = gql`
    mutation AddCartItem($where: CartItemWhereInput!, $data: CartItemDataInput!) {
  addCartItem(where: $where, data: $data) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useAddCartItemMutation() {
    return Urql.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument);
};
export const CreateCartDocument = gql`
    mutation CreateCart($where: CartWhereInput!) {
  createCart(where: $where) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useCreateCartMutation() {
    return Urql.useMutation<CreateCartMutation, CreateCartMutationVariables>(CreateCartDocument);
};
export const CreateCategoryDocument = gql`
    mutation CreateCategory($data: CategoryDataInput!) {
  createPositionCategory(data: $data) {
    id
    name
    icon
    custom
  }
}
    `;

export function useCreateCategoryMutation() {
    return Urql.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument);
};
export const RemoveCartItemDocument = gql`
    mutation RemoveCartItem($where: CartItemWhereInput!, $partNo: String!) {
  removeCartItem(where: $where, partNo: $partNo) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useRemoveCartItemMutation() {
    return Urql.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument);
};
export const RemoveCategoryDocument = gql`
    mutation RemoveCategory($id: String!) {
  removePositionCategory(id: $id) {
    id
    name
    icon
    custom
  }
}
    `;

export function useRemoveCategoryMutation() {
    return Urql.useMutation<RemoveCategoryMutation, RemoveCategoryMutationVariables>(RemoveCategoryDocument);
};
export const UpdateCategoryPositionsDocument = gql`
    mutation UpdateCategoryPositions($id: String!, $positions: [String!]!, $mode: UpdateMode) {
  updateCategoryPositions(id: $id, positions: $positions, mode: $mode) {
    total
    items {
      id
    }
  }
}
    `;

export function useUpdateCategoryPositionsMutation() {
    return Urql.useMutation<UpdateCategoryPositionsMutation, UpdateCategoryPositionsMutationVariables>(UpdateCategoryPositionsDocument);
};
export const CartDocument = gql`
    query Cart($where: CartWhereInput!) {
  cart(where: $where) {
    name
    items {
      name
      qty
      partnr
      note
    }
  }
}
    `;

export function useCartQuery(options: Omit<Urql.UseQueryArgs<CartQueryVariables>, "query">) {
    return Urql.useQuery<CartQuery>({ query: CartDocument, ...options });
};
export const CartsDocument = gql`
    query Carts {
  carts
}
    `;

export function useCartsQuery(options?: Omit<Urql.UseQueryArgs<CartsQueryVariables>, "query">) {
    return Urql.useQuery<CartsQuery>({ query: CartsDocument, ...options });
};
export const PositionCategoriesDocument = gql`
    query PositionCategories($id: String!) {
  positionCategories(id: $id) {
    id
    name
    icon
    custom
    positions
  }
}
    `;

export function usePositionCategoriesQuery(options: Omit<Urql.UseQueryArgs<PositionCategoriesQueryVariables>, "query">) {
    return Urql.useQuery<PositionCategoriesQuery>({ query: PositionCategoriesDocument, ...options });
};
export const DocInfoDocument = gql`
    query DocInfo($where: DocWhereInput!) {
  docInfo(where: $where) {
    id
    title
    type
    rev
    revdate
    revno
    spl
    version
    applicableDocs {
      id
      title
      type
      status
      refid
      src
    }
  }
}
    `;

export function useDocInfoQuery(options: Omit<Urql.UseQueryArgs<DocInfoQueryVariables>, "query">) {
    return Urql.useQuery<DocInfoQuery>({ query: DocInfoDocument, ...options });
};
export const DocTocDocument = gql`
    query DocToc($where: DocWhereInput!) {
  docToc(where: $where) {
    id
    title
    target {
      docid
      anchorid
    }
    nodes {
      id
      title
      target {
        docid
        anchorid
      }
      nodes {
        id
        title
        target {
          docid
          anchorid
        }
        nodes {
          id
          title
          target {
            docid
            anchorid
          }
        }
      }
    }
  }
}
    `;

export function useDocTocQuery(options: Omit<Urql.UseQueryArgs<DocTocQueryVariables>, "query">) {
    return Urql.useQuery<DocTocQuery>({ query: DocTocDocument, ...options });
};
export const HealthDocument = gql`
    query Health {
  health {
    health
    userAgent
  }
}
    `;

export function useHealthQuery(options?: Omit<Urql.UseQueryArgs<HealthQueryVariables>, "query">) {
    return Urql.useQuery<HealthQuery>({ query: HealthDocument, ...options });
};
export const PositionDocument = gql`
    query Position($id: String!, $posId: String!, $specification:String, $language:String) {
  position(id: $id, posId: $posId, specification:$specification, language:$language) {
    id
    description
    type
    notes
    pos
    levels
    specifications
    references {
      id
      name
      type
      page
    }
    subitems {
      id
      description
      type
      notes
      specifications
      references {
        id
        name
        type
      }
      keywords {
        key
        text
      }
    }
    keywords {
      key
      text
    }
    referencedPositions {
      id
      description
      type
      notes
      pos
      levels
      specifications
    }
  }
}
    `;

export function usePositionQuery(options: Omit<Urql.UseQueryArgs<PositionQueryVariables>, "query">) {
    return Urql.useQuery<PositionQuery>({ query: PositionDocument, ...options });
};
export const PositionsDocument = gql`
    query Positions($where: PositionWhereInput!, $pagination: PaginationInput) {
  positions(where: $where, pagination: $pagination) {
    total
    filter {
      levels
      types
    }
    items {
      id
      description
      type
      notes
      pos
      levels
      specifications
      references {
        id
        name
        type
      }
      subitems {
        id
        description
        type
        notes
        specifications
        references {
          id
          name
          type
        }
        keywords {
          key
          text
        }
      }
      keywords {
        key
        text
      }
    }
  }
}
    `;

export function usePositionsQuery(options: Omit<Urql.UseQueryArgs<PositionsQueryVariables>, "query">) {
    return Urql.useQuery<PositionsQuery>({ query: PositionsDocument, ...options });
};
export const SidebarLevels = gql`
    query Positions($where: PositionWhereInput!, $pagination: PaginationInput) {
  positions(where: $where, pagination: $pagination) {
    filter {
      levels
      types
    }
    specifications{
        name
        path
        dbpath
        specification
    }
  }
}
    `;

export function useSidebarLevelsQuery(options: Omit<Urql.UseQueryArgs<PositionsQueryVariables>, "query">) {
    return Urql.useQuery<SidebarLevelsQuery>({ query: SidebarLevels, ...options });
};
export const SearchDocument = gql`
    query Search($where: SearchWhereInput!, $pagination: PaginationInput!) {
  search(where: $where, pagination: $pagination) {
    total
    items {
      id
      title
      subtitle
      type
      score
      effect
      task
      taskid
      doctitle
      pdf
      target {
        docid
        anchorid
        link
      }
      matches {
        text
      }
    }
  }
}
    `;

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, "query">) {
    return Urql.useQuery<SearchQuery>({ query: SearchDocument, ...options });
};
export const SearchFilterDocument = gql`
    query SearchFilter($where: SearchWhereInput!, $field: String!) {
  searchFilter(where: $where, field: $field)
}
    `;

export function useSearchFilterQuery(options: Omit<Urql.UseQueryArgs<SearchFilterQueryVariables>, "query">) {
    return Urql.useQuery<SearchFilterQuery>({ query: SearchFilterDocument, ...options });
};
export const TenderDocument = gql`
    query Tender($id: String!) {
  tender(id: $id) {
    id
    name
    customer
    eventNo
    modified
    created
    files{
    name
    path
    dbpath
    specification
    fileUrl
    }
  }
}
    `;

export function useTenderQuery(options: Omit<Urql.UseQueryArgs<TenderQueryVariables>, "query">) {
    return Urql.useQuery<TenderQuery>({ query: TenderDocument, ...options });
};
export const TendersDocument = gql`
    query Tenders {
  tenders {
    id
    name
    customer
    eventNo
    modified
    created
    fileQuantity
    files
    {
    name
    path
    dbpath
    specification
    }
  }
}
    `;

export function useTendersQuery(options?: Omit<Urql.UseQueryArgs<TendersQueryVariables>, "query">) {
    return Urql.useQuery<TendersQuery>({ query: TendersDocument, ...options });
};
export const UserDocument = gql`
    query User {
  user {
    username
    name
  }
}
    `;

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<UserQueryVariables>, "query">) {
    return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};