// Copyright (C) 2022 TANNER AG

import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppLogo from "./AppLogo";
import AppToolbar from "./AppToolbar";
import AppMenu from "./AppMenu";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { lightFontFamily } from "../../theme";

const Appbar: React.FC = () => {
    return (
        <MuiAppBar
            position="sticky"
            elevation={0}
            sx={{
                backgroundColor: "background.paper",
                color: "text.secondary",
                borderBottom: "1px solid",
                borderColor: "grey.50",
                zIndex: 1100
            }}
        >
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{ minHeight: { sm: 56, md: 76 } }}>
                    <Stack>
                        <Typography variant="body2" sx={{ color: "grey.300", fontFamily: lightFontFamily }}>
                            MAN MyBus
                        </Typography>
                        <AppMenu />
                    </Stack>
                    <AppToolbar />
                    <AppLogo />
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
};

export default Appbar;
